<template src="./GroupORD.html"></template>

<script>
export default {
  name: 'GroupORD',
  data: () => ({
    params: {},
    flags: {
      error: false,
      isGood: false,
      loaded: false,
    },

    ticket_url: '',
    ticket_email: '',
    ticket_order: 0,

    desc: ' ',
    id: 0,
  }),
  created() {
    if (this.$route.params.smartlink) {
      this.params = this.$route.params;
      this.loadSmartLink();
    } else {
      console.log('неверные данные от банка');
      return '/none';
    }
  },
  methods: {
    resetData() {
      console.log('Попытка что то обновить');
      this.loadSmartLink();
    },

    loadSmartLink() {
      let pr2 = this.$store.state.api_dk.LoadSmartLink(this.$route.params.smartlink);
      pr2.then((data) => {
        console.log(data);
        if (data.b) {
          this.flags.error = false;
          this.desc = data.description;
          this.id = data.id;
          this.flags.isGood = true;
        } else {
          this.flags.isGood = false;
          this.flags.error = true;
        }
        this.flags.loaded = true;
      })
    },
  },
};
</script>

<style scoped></style>
